
.delete-button {
    text-align: center;
    background-color: rgba(0,0,0,0);
    outline: none;
    border:none;
    padding: 0px;
}

.delete-button:hover {
    cursor: pointer;
}