.dash-container {
    width: 95vw;
    height: 100vh;
    margin: 0px auto;
    padding-top:50px;
}

.dash-header {
    max-width:700px;
    height:200px;
    margin: 0px auto;
    padding: 20px;
    
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
    box-sizing: border-box;
}

.dash-header-header {
    font-size: 25px;
}

.dash-body {
    max-width:700px;
    height: calc(100vh - 250px);
    margin: 20px auto;
    padding: 20px;
    
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
    box-sizing: border-box;
}

.dash-body-header {
    height:30px;
    font-size: 20px;
}

.dash-posts-list {
    height: calc(100% - 30px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.dash-loader {
    margin-top: 50px !important;
}


