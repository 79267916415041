
.follows {
    margin: auto;
    height: 50px;
    display: flex;
    flex-direction: row;
}

.follow-display {
    height: 100%;
    width: 70px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.follow-display-count {
    font-weight:600;
    height: 20px;
    line-height: 20px;
}

.follow-display-label {
    font-size: 12px;
}

.follow-button {
    height: 34px;
    width: 80px;
    margin: 8px;
    font-size: 12px;
}

.follow-loader {
    height: 4px !important;
    width: 4px !important;
    margin-top: 9px !important;
}

@media only screen and (max-width: 850px){
    .follow-display {
        width: 60px;
    }
    .follow-button {
        width: 70px;
        margin: 8px 4px;
    }
}
