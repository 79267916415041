.hamburger {
    height: 50px;
    width: 50px;
}

.hamburger-button {
    margin: auto;
    height: 100%;
    width: 100%;
    padding: 0px;

    background-color: rgba(0, 0, 0, 0);
    text-align: center;
    font-size: 25px;
    outline: none;
    border: none;
}

.hamburger-background {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 49;
    left:0;
    top:0;

    background-color: rgba(0,0,0,0.05);
}

.hamburger-menu {
    position: relative;
    height: 350px;
    width: 300px;
    z-index: 50;
    
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background-color: white;

    display: flex;
    flex-direction: column;

}

.hamburger-nav-item {
    height:50px;
    width: 85%;
    margin: 0px auto;
    line-height: 50px;
    border-bottom: 1px solid rgb(243, 243, 243);
}

.hamburger-search {
    height:60px;
    margin: 10px auto 0px auto;
}

.hamburger-sign-out {
    font-size: 12px;
    height: 35px;
    width: 80px;
    margin: auto auto 10px auto
}
