.post-compose {
    background-color: white;

    width: 92%;
    max-height: 800px;
    margin: 10px 4%;

    display: flex; 
    flex-direction: column;
    position:relative;

    border-radius: 15px;
    overflow: hidden;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.post-creating-loader {
    background-color: white;

    width: 92%;
    height: 225px;
    margin: 20px 4%;

    border-radius: 15px;
    overflow: hidden;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.post-creating-animation {
    background: linear-gradient(182deg, #dadada, #c7c7c7);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 1.5s ease-in infinite;
    -moz-animation: AnimationName 1.5s ease-in infinite;
    -o-animation: AnimationName 1.5s ease-in infinite;
    animation: AnimationName 1.5s ease-in infinite;

    height: 100%;
    width: 100%;
    z-index: 1;
}

@-webkit-keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}
@-o-keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}
@keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}

/* 
@keyframes creatingShimmer {
    0% {
        background-position: 0px 0;
    }

    100% {
        background-position: 120em 0;

    }
}

.post-creating-animation {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: creatingShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    height: 100%;
    width: 100%;
    z-index: 1;
} */

.post-image-preview {
    max-height: 420px;
    width: 100%;
    margin-bottom:-20px;
    z-index: 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.post-image-preview img {
    height: auto;
    width: 100%;
    margin: auto;
}

.post-compose form {
    background-color: white;
    width: calc(100%-40px);
    min-height:100%;

    padding:20px;
    z-index: 1;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-evenly;

    border-radius: 15px;
}

.file-upload {
    color: rgb(26, 32, 43);
    background-color: white;

    border-width: 2px;
    border-radius: 100px;
    border-style: solid;
    
    font-weight: 700;
    font-size: 12px;
    
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    height: 32px;
    width: 110px;

    text-align: center;
    line-height: 32px;

    margin-left: auto;
    margin-right: 20px;
}

.file-upload label:hover {
    cursor: pointer;
}

.compose-body {
    width: 100%;
    min-height:25px;
    word-wrap: break-word;
    font-family: 'Roboto', sans-serif;
    border: 0;
}

.compose-operations {
    width: 100%;
    display: flex;
}

.cancel-button {
    font-size: 12px;
    font-size: 12px;
    height: 34px;
    width: 70px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s;
    transition: visibility 0.5s;
}

.submit-post {
    height:36px;
    width: 80px;
    
}

input[type="file"] {
    display: none;
}