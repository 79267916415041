.noauth {
    height:50px;
    width:100%;
    line-height: 50px;
    margin: 40vh auto;
    text-align:center;
    color:grey;
    font-size: 14px;
}


