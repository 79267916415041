.users-container {
    height: calc(100vh - 50px);
    width: 95vw;
    margin: 50px auto 0px auto;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
    box-sizing: border-box;
    padding: 10px;
}

.users-list {
    max-width: 700px;
    margin: auto;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.users-header {
    margin: 10px;
    font-size: 25px;
}

.users-loader {
    margin-top: 50px !important;
}