@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');



html, body, ul, p {    
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: white;
  font-family: 'Roboto', sans-serif !important;
}

a, a:link, a:visited, a:hover {
  text-decoration: none;
  color:black;
}

input {
  background-color: white;
  border: none !important;
  outline: none !important;
  padding:0px;
} 

input:active {
  border: none !important;
  outline: none !important;
}

textarea {
  /* overflow-y:auto; */
  resize: none;
  border: none !important;
  outline: none !important;
}

textarea:active {
  border: none !important;
  outline: none !important;
}

.router {
  height:100vh;
  width:100vw;
  overflow: hidden; 
}

/* chrome */
::-webkit-scrollbar {
  width: 0px;
}

* { /* good ol' firefox*/
    scrollbar-width: none;
}