

.user-row {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: solid 1px rgba(0,0,0,0.1);
}

.user-avatar {
    width: 50px;
    height:50px;
    border-radius: 100px;
}

.user-name {
    line-height: 50px;
    margin-left: 10px;
}

.user-username {
    line-height: 50px;
    margin-left: 10px;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
}

.user-del {
    width:50px;
    margin-left: auto;
}