@-webkit-keyframes rays {
    from {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes rays {
    from {
        opacity: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.rays:after,
.rays:before {
    border-radius: 100%;
    content: '';
    position: absolute;
}

.rays:after {
    -webkit-animation: rays 1.5s infinite;
    animation: rays 1.5s infinite;
    border: 10px solid black;
    height: 100px;
    width: 100px;
}

.rays:before {
    background: black;
    height: 20px;
    width: 20px;
}

.rays {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top:calc(15vh + 200px)
}