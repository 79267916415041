.comment-composer {
    width: 95%;
    margin: 10px 0px;
    margin-left:5%;
}

.comment-composer form {
    display: flex;
    flex-direction: row;
}

.comment-composer-body {
    resize: none;
    min-height:25px;
    height:25px;
    line-height: 25px;
    width: calc(100% - 100px);
}

.comment-composer-operations {
    width:20%;
}

.submit-comment {
    height:25px;
    width: 80px;
    margin:auto;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.5s;
    transition: opacity 0.5s;
    padding: 0px !important;
}

.comment-create-loader-container {
    position: relative;
}

.comment-create-loader {
    position: absolute;
    height: 6px !important;
    width: 6px !important;
    top:10px;
    left: -50px;
}
