.signup-container {
    background-color: white;
    min-height: 400px; 
    width:90vw;
    max-width: 500px;
    margin: 15vh auto 0px auto;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.signup-header {
    margin: 15px auto;
}

.signup-form {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.signup-form-top {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.signup-form-bottom {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin: 10px 0px;
}

.grid-item {
    max-width: 50%;
    min-width: 200px;
    height: 40px;
    margin: 5px 10px;
}

.signup-button {
    height: 40px;
    width: 100px;
    font-size: 14px;
    margin-bottom: 10px;
}

.signup-text {
    height: 40px;
    text-align: center;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.signup-text input {
    height:100%;
    width: 100%;
    text-align: center;
    border-radius: 15px;
}

.username-container {
    position:relative;
}

.username-at {
    position:absolute;
    top:8px;
    left:10px;
    color: grey;
}


.signup-upload {
    color: rgb(26, 32, 43);
    position: relative;

    border-width: 2px;
    border-radius: 100px;
    border-style: solid;
    
    font-weight: 700;
    font-size: 12px;
    
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    height: 32px;
    width: 160px;

    text-align: center;
    line-height: 32px;

    margin: 5px 10px;
}

.signup-upload label:hover {
    cursor: pointer;
}

.upload-preview-container {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 1px;
    left: 167px;
    display: none;
}

.upload-preview {
    height: 100%;
    width: 100%;
    border-radius: 100%;
}

.bio-compose {
    font-family: 'Roboto', sans-serif;
    word-wrap: break-word;
    border: 0;
    padding: 10px;
    box-sizing: border-box;
    height: 40px;
    
    text-align: center;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    line-height: 20px;

}

.signup-upload-loader {
    position:absolute;
    height: 5px !important;
    width: 5px !important;
    top: 12px !important;
    left: 12px !important;
}

.validation-error-container {
    position: relative;
}

.validation-error {
    position:absolute;
    height: 20px;
    min-width: 125px;
    top: -26px;
    right: 0px;
    
    box-sizing: border-box;
    padding: 0px 5px;
    
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    font-size: 10px;
    text-align: center;
    line-height: 20px;

    color:rgb(153, 0, 0);
    background-color:rgba(255, 224, 224, 0.9);
}
