.button-primary {
    background-color: rgb(28, 28, 33);
    color: rgb(240, 240, 240);

    padding: 5px;
    border-style: solid;
    border-radius: 100px;
    border-color: rgb(28, 28, 33);
    
    font-weight: 700;
    font-size: 14px;
    
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }
  
  .button-primary:hover {
    cursor: pointer;
  }
  