@-webkit-keyframes meetup {

    0%,
    100% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
    }

    50% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(200%);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(200%);
    }
}

@keyframes meetup {

    0%,
    100% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
    }

    50% {
        -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(200%);
        transform: rotate(calc(var(--rotation) * 1deg)) translateY(200%);
    }
}

.meetup {
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    height: 10px;
    width: 10px;
    margin: auto;
}

.meetup div {
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-animation: meetup 1.25s infinite ease;
    animation: meetup 1.25s infinite ease;
    border-radius: 100%;
    /* background-color: rgb(231, 231, 231); */
    background-color: rgb(66, 66, 66);
}

.meetup div:nth-child(1) {
    --rotation: 90;
}

.meetup div:nth-child(2) {
    --rotation: 180;
}

.meetup div:nth-child(3) {
    --rotation: 270;
}

.meetup div:nth-child(4) {
    --rotation: 360;
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}