.profile-container {
    width: 95vw;
    height: 100vh;
    margin: auto;
    padding-top:50px;
}

.profile-scroller {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;
}

.profile-header {
    max-width: 80%;
    height: auto;
    margin: 0px auto 20px auto;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
}

.profile-cover {
    height: 300px;
    width: 100%;
    background-color:grey;

    display:flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
}

.profile-cover img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
}

.update-cover {
    position: absolute;
    height: 300px;
    width: 100%;

    z-index: 1;
    background-color: rgba(0,0,0,0.2);
    opacity: 0;
    transition: opacity 0.5s ease;
    
    line-height: 300px;
    text-align: center;

    font-size: 20px;
    color: white;
}

.update-cover:hover {
    opacity: 1;
}

.update-cover label {
    cursor: pointer;

}

.profile-avatar {
    position: absolute;
    height: 150px;
    width: 150px;
    margin-left: 50px;
}

.profile-avatar-img {
    height: inherit;
    width: inherit;
    margin-top: -75px;
    border-radius: 75px;
    z-index: 2;
    position: relative;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
}

.profile-details {
    background-color: white;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
}

.profile-details-text {
    width: 80%;
}

.profile-details-header {
    height: 50px;
    display: flex;
    align-items: center;
}

.profile-details-name {
    font-size: 25px;
    margin-left: 210px;
}

.follower-container {
    margin-left: 10px;
}

.profile-details-footer {
    height: 50px; 
    width: 100%;
}

.profile-details-bio {
    height: 100%;
    font-size: 14px;
    margin-left: 200px;
}

.profile-post-container {
    max-width: 700px;
    margin:auto;
}

.update-avatar {
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    height: 150px;
    width: 150px;
    margin-left: 50px;
    margin-top: -75px;
    border-radius: 100px;
    z-index: 5;
    opacity: 0;
    line-height: 150px;
    text-align: center;
    transition: opacity 0.5s ease;
    font-size: 20px;
    color: white;
}

.update-avatar:hover {
    opacity: 1;
}

.update-avatar label {
    cursor: pointer;
}

.profile-loader {
    margin: 100px auto !important;
}

.profile-posts-loader {
    margin-top: 50px !important;
}

.profile-posts-loader div {
    background-color: grey;
}

@media only screen and (max-width: 850px){
    .profile-header {
        max-width: 100%;
    }
    .profile-avatar {
        height: 100px;
        width: 100px;
        margin-left: 20px;
    }
    .profile-avatar-img {
        margin-top: -90px;
        border-radius: 50px;
    }
    .profile-details-name {
        font-size: 16px;
        font-weight: 700;
        margin-left: 3%;
    }
    .profile-details-bio {
        height: 100%;
        margin-left: 3%;
        font-size: 12px;
    }
    .update-avatar {
        height: 100px;
        width: 100px;
        margin-left: 20px;
        margin-top: -90px;
        line-height: 100px;
    }
    .follower-container {
        margin-left: auto;
        margin-right: 10px;
    }
    
}



