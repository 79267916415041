.navbar {
    height: 50px;
    width: 100vw;
    position: fixed;
    background-color: white;
    z-index: 10;
}

.nav-list {
    width: 95vw;
    margin: auto;
    text-decoration: none;
    list-style: none;
}

.nav-wide-screen {
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
}

.nav-narrow-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.nav-item {
    height: 50px;
    width: 50px;
    margin: 0px 5px;
    line-height: 50px;
    z-index: 12;
}

.center {
    width: calc(95vw*0.9);
    height: 100%;
    margin: auto;
    position: absolute;
    z-index: 11;
}

.profile {
    width: auto;
    display: flex;
    flex-direction: row;
}

.username {
    margin: 0px 10px;
}

.nav-avatar {
    height: 40px;
    width: 40px;
    margin: 5px 0px;
}

.nav-login {
    height: 50px;
    margin: 0px 5px;
    line-height: 50px;
    text-align: center;
    margin-left: auto;
}

.nav-login-button {
    height: 40px;
    width: 80px;
}

.nav-sign-out {
    font-size: 12px;
    height: 35px;
    width: 80px;
    margin: 7.5px 5px;
    z-index: 12;
}

.end {
    margin-left: auto;
}