.feed-container {
    width: 95vw;
    height: 100vh;
    margin: auto;
}

.posts-list {
    width: 100%;
    max-width: 700px;
    margin:auto;
    height:100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 50px;
}

.feed-end {
    height: 100px;
    width: 100%;

    color: rgb(209, 209, 209);
    text-align: center;
    font-size: 12px;
}

.feed-loader {
    margin: 100px auto !important;
}