.comment {
    width: 95%;
    margin-top: 5px;
    margin-left:5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.comment-avatar {
    height: 30px;
    width: 30px;
    border-radius: 100px;
}

.author {
    font-size: 12px; 
    color:grey;
}

.comment-text {
    height: auto;
    width: calc(100% - 80px);
    margin-left:5px;
    word-wrap: break-word;
}

.timestamp {
    font-size: 10px;
}

.comment-body {
    width: 98%;
    margin-left:auto;
    min-height:25px;
    line-height:25px;
    font-size: 14px;
}

.comment-delete {
    margin-left: auto;
    width: 20px;
}

.comment-deleting-loader-container {
    position: relative;
    width:40px;
    background-color: green;
}

.comment-deleting-loader {
    position: absolute;
    height: 5px !important;
    width:5px !important;
    right:2px;
}

.comment-deleting-cover {
    height: 100%;
    width: 104%;
    background-color: rgba(0,0,0,0.05);
    position: absolute;
    z-index: 10;
    border-radius: 10px;
    left:-2%;
}