.post-container {
    width: 92%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 20px 4%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: box-shadow 0.5s;
    position: relative;
}

.post-deleting-cover {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.05);
    position: absolute;
    z-index: 10;
}

.post {
    width: 100%;
    height:auto;

    display: flex;
    flex-direction: column;

    position: relative;
}

.post-image {
    max-height: 420px;
    width: 100%;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.post-image img {
    height: auto;
    width: 100%;
    margin: auto;
    margin-bottom: -20px;
    position:relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.image-placeholder {
    height: 420px;
    width: 100%;
}

.image-placeholder-animation {
    background: linear-gradient(182deg, #dadada, #ababab);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 1.5s linear infinite;
    -moz-animation: AnimationName 1.5s linear infinite;
    -o-animation: AnimationName 1.5s linear infinite;
    animation: AnimationName 1.5s linear infinite;

    height: 110%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top:-20px;
}

@-webkit-keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}
@-o-keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}
@keyframes AnimationName {
    0%{background-position:50% 0%}
    50%{background-position:51% 100%}
    100%{background-position:50% 0%}
}

/* @keyframes placeHolderShimmer {
    0% {
        background-position: 0px 0;
    }

    100% {
        background-position: 120em 0;

    }
}

.image-placeholder-animation {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    height: 110%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top:-20px;
} */


.post-body {
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 2;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.post-details {
    height: 50px;
    display: flex;
    flex-direction: row;
}

.post-details-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}

.post-details-delete {
    height: 50px;
    width: 50px;
    margin: 0px 10px;
    position: relative;
}

.post-details-delete-loader {
    position:absolute;
    top: 18px;
    left: 15px;
}

.post-details-delete-button {
    width: 100%;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
}

.post-details-img {
    margin-left: auto;
}

.post-author {
    font-size: large;
    font-weight: 600;
}

.post-meta {
    color: rgba(149, 157, 165, 0.6);
    width: 100%;
    display: flex;
}

.post-meta div {
    font-size: small;
    margin-right: 20px;
}

time {
    font-size: small;
}

.author-avatar {
    height: 50px;
    width: 50px;
    border-radius: 100px;
}

.post-text-body {
    margin: 20px 0px;
    word-wrap: break-word;
}

.comments-container {
    background-color: green;

}

.comments-loader {
    height: 5px !important;
    width: 5px !important;
}

@media only screen and (max-width: 850px) {
    @keyframes placeHolderShimmer {
        0% {
            background-position: 0px 0;
        }
    
        100% {
            background-position: 58em 0;
    
        }
    }
}