.button-secondary {
    padding: 5px;
    
    border: solid 2px black;
    border-radius: 100px;
    
    font-weight: 700;
    
    color: rgb(26, 32, 43);
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
  }
  
  .button-secondary:hover {
    cursor: pointer;
  }
  