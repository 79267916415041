.notfound-container {
    height:50px;
    line-height: 50px;
    margin: 40vh auto;
    text-align:center;
    color: grey;
}

.notfound-header {
    font-size: 30px;
    color:black;
}

.notfound-body {
    color:grey;
    font-size: 14px;
}