
.interactions-container {
    width: 100%;
    height: 30px;
    display: flex;
    line-height: 30px;
}

.likes {
    margin-left:auto;
    margin-right: 10px;
}

.likes i {
    margin: 0px 5px;
}

.like {
    cursor: pointer;
}

.like-loader {
    height: 5px !important;
    width: 5px !important;
    margin-top: 12px !important;
}

.like-loader div {
    background-color: black;
}